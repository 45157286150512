import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Chat from "@material-ui/icons/Chat";
// import Settings from "@material-ui/icons/Settings";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Fingerprint from "@material-ui/icons/Fingerprint";
import SettingsSuggest from "@material-ui/icons/Settings";
import School from "@material-ui/icons/School";
import Build from "@material-ui/icons/Build";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section} id="Product">
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk product</h2>
          <h5 className={classes.description}>
            We offer a wide range of services and solutions. Too many to mention
            by name.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Training and Consulting"
              description="Practical and effective. We work side-by-side with your teams to develop training programs and consulting engagements that make a difference."
              icon={School}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Custom Software Development"
              description="Always use the right tool for the job. If the tool doesn't exist, we'll build it. Whether it's your first website or expanding to global scale, we got you covered."
              icon={Build}
              iconColor="warning"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Business Process Automation"
              description="Automated processes speed up slow, error-prone manual activities, ensure flawless execution while reducing costs. From automating tedious tasks to your most critical business processes, we have expertise and solutions for you."
              icon={SettingsSuggest}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
