/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";
// import SectionTeam from "./Sections/SectionTeam.js";
// import SectionWork from "./Sections/SectionWork.js";
import constants from "constants/constants.js";
import SectionContact from "./Sections/SectionContacts";
const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        color="transparent"
        brand={constants.BRAND}
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "primary",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/bg8.jpg").default} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Your Story Starts With YES.</h1>
              <h4>
                We believe every business deserves to be empowered with modern purpose-built technology solutions.
                Our vendor-certified professionals provide development and consulting for projects of any size.
              </h4>
              <br />
              <Button
                color="danger"
                size="lg"
                href="#Contact"
                // target="_blank"
              >
                {/*<i className="fas fa-play" />*/}
                Get in touch
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct />
          {/*<SectionTeam />*/}
          {/*<SectionWork />*/}

        </div>
      </div>
      <SectionContact />

      <Footer
        content={
          <div>
            <div className={classes.left}>
              {/*<List className={classes.list}>*/}
              {/*  <ListItem className={classes.inlineBlock}>*/}
              {/*    <a*/}
              {/*      href="https://www.example.com/?ref=mkpr-landing"*/}
              {/*      target="_blank"*/}
              {/*      className={classes.block}*/}
              {/*    >*/}
              {/*      Creative Tim*/}
              {/*    </a>*/}
              {/*  </ListItem>*/}
              {/*  <ListItem className={classes.inlineBlock}>*/}
              {/*    <a*/}
              {/*      href="https://www.example.com/presentation?ref=mkpr-landing"*/}
              {/*      target="_blank"*/}
              {/*      className={classes.block}*/}
              {/*    >*/}
              {/*      About us*/}
              {/*    </a>*/}
              {/*  </ListItem>*/}
              {/*  <ListItem className={classes.inlineBlock}>*/}
              {/*    <a href="//blog.example.com/" className={classes.block}>*/}
              {/*      Blog*/}
              {/*    </a>*/}
              {/*  </ListItem>*/}
              {/*  <ListItem className={classes.inlineBlock}>*/}
              {/*    <a*/}
              {/*      href="https://www.example.com/license?ref=mkpr-landing"*/}
              {/*      target="_blank"*/}
              {/*      className={classes.block}*/}
              {/*    >*/}
              {/*      Licenses*/}
              {/*    </a>*/}
              {/*  </ListItem>*/}
              {/*</List>*/}
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> by YES
            </div>
          </div>
        }
      />
    </div>
  );
}
